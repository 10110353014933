<template>
  <div class="overflow-scroll">
    <div style="min-width: 1240px;">
      <div style="margin-bottom: 24px">
        <a-card title="门店信息录入">
          <div>
            <a-steps :current="current">
              <a-popover
                slot="progressDot"
                slot-scope="{ title, prefixCls }"
              >
                <template slot="content">
                  <span>{{ title }}</span>
                </template>
                <span :class="`${prefixCls}-icon-dot`"></span>
              </a-popover>
              <a-step
                v-for="item in steps"
                :key="item.title"
                :title="item.title"
              />
            </a-steps>
          </div>
        </a-card>
      </div>
      <div v-if="current == 0">
        <a-card>
          <DescriptionList
            title="门店信息"
            size="large"
            style="margin-bottom: 24px;"
            class="storeNewStoreLabelWidth"
          >
            <a-card :bordered="false" style="margin-left: -16px">
              <div class="table-operator">
                <a-form layout="inline" :form="form">
                  <a-row :gutter="5" style="line-height: 4;">
                    <a-col :md="8" :sm="24">
                      <a-form-item label="门店编号">
                        <a-input
                          style="width: 240px"
                          v-decorator="[
                            'code',
                            {
                              rules: [
                                {
                                  required: true,
                                  message: '请输入！'
                                }
                              ],
                              initialValue: this.record0.code
                                ? this.record0.code
                                : ''
                            }
                          ]"
                          placeholder="请填写"
                        />
                      </a-form-item>
                    </a-col>
                    <a-col :md="8" :sm="24">
                      <a-form-item label="门店类型">
                        <a-select
                          style="width: 240px"
                          v-decorator="[
                            'type',
                            {
                              rules: [
                                {
                                  required: true,
                                  message: '请输入！'
                                }
                              ],
                              initialValue: this.showMessage(this.record0.type)
                            }
                          ]"
                          placeholder="请选择"
                        >
                          <a-select-option
                            v-for="item in storeType"
                            :key="item.id"
                          >
                            {{ item.name }}
                          </a-select-option>
                        </a-select>
                      </a-form-item>
                    </a-col>
                    <a-col :md="8" :sm="24">
                      <a-form-item label="门店性质">
                        <a-select
                          style="width: 240px"
                          v-decorator="[
                            'attribute',
                            {
                              rules: [
                                {
                                  required: true,
                                  message: '请输入！'
                                }
                              ],
                              initialValue: this.showMessage(
                                this.record0.attribute
                              )
                            }
                          ]"
                          placeholder="请选择"
                        >
                          <a-select-option
                            v-for="item in storeNature"
                            :key="item.id"
                          >
                            {{ item.name }}
                          </a-select-option>
                        </a-select>
                      </a-form-item>
                    </a-col>
                    <a-col :md="8" :sm="24">
                      <a-form-item label="门店状态">
                        <a-select
                          style="width: 240px"
                          v-decorator="[
                            'status',
                            {
                              rules: [
                                {
                                  required: true,
                                  message: '请输入！'
                                }
                              ],
                              initialValue: this.showMessage(
                                this.record0.status
                              )
                            }
                          ]"
                          placeholder="请选择"
                        >
                          <a-select-option
                            v-for="item in storeStatus"
                            :key="item.id"
                          >
                            {{ item.name }}
                          </a-select-option>
                        </a-select>
                      </a-form-item>
                    </a-col>
                    <a-col :md="8" :sm="24">
                      <a-form-item label="城市">
                        <a-select
                          @change="selectCity"
                          style="width: 240px"
                          v-decorator="[
                            'city',
                            {
                              rules: [
                                {
                                  required: true,
                                  message: '请输入！'
                                }
                              ],
                              initialValue: this.showMessage(this.record0.city)
                            }
                          ]"
                          placeholder="请选择"
                        >
                          <a-select-option
                            v-for="item in userCities"
                            :key="item.id"
                          >
                            {{ item.name }}
                          </a-select-option>
                        </a-select>
                      </a-form-item>
                    </a-col>
                    <a-col :md="8" :sm="24">
                      <a-form-item label="所属厨房">
                        <a-select
                          style="width: 240px"
                          v-decorator="[
                            'facilityBrand',
                            {
                              rules: [
                                {
                                  required: true,
                                  message: '请输入！'
                                }
                              ],
                              initialValue: this.showMessage(
                                this.record0.facilityBrand
                              )
                            }
                          ]"
                          placeholder="请选择"
                        >
                          <a-select-option
                            v-for="item in brandNameList"
                            :key="item.code"
                          >
                            {{ item.optionText }}
                          </a-select-option>
                        </a-select>
                      </a-form-item>
                    </a-col>
                    <a-col :md="24" :sm="24">
                      <a-form-item label="门店名称">
                        <a-input
                          style="width: 607px"
                          v-decorator="[
                            'name',
                            {
                              rules: [
                                {
                                  required: true,
                                  message: '请输入！'
                                }
                              ],
                              initialValue: this.record0.name
                            }
                          ]"
                          placeholder="请输入"
                        />
                      </a-form-item>
                    </a-col>
                    <a-col :md="24" :sm="24">
                      <a-form-item label="门店地址">
                        <a-input
                          style="width: 607px"
                          v-decorator="[
                            'address',
                            {
                              rules: [
                                {
                                  required: true,
                                  message: '请输入！'
                                }
                              ],
                              initialValue: this.record0.address
                            }
                          ]"
                          placeholder="请输入"
                        />
                      </a-form-item>
                    </a-col>
                  </a-row>
                </a-form>
                <a-form layout="inline" :form="form" style="margin-top: 24px">
                  <a-form-item label="上传图片">
                    <template>
                      <div class="clearfix" style="display: inline-block">
                        <a-upload
                          listType="picture-card"
                          accept=".png,.jpg"
                          :fileList="fileImg"
                          withCredentials
                          name="multipartFile"
                          :action="this.uploadUrl()"
                          @preview="handlePreview"
                          @change="handleChange"
                        >
                          <div v-if="fileImg.length < 5">
                            <a-icon type="plus" />
                            <div class="ant-upload-text">上传</div>
                          </div>
                        </a-upload>
                        <a-modal
                          :visible="previewVisible"
                          :footer="null"
                          @cancel="handleCancel"
                        >
                          <!--                          <a-carousel>-->
                          <!--                            <img-->
                          <!--                                    alt="example"-->
                          <!--                                    style="width: 100%"-->
                          <!--                                    :src="previewImage"-->
                          <!--                            />-->
                          <!--                            <div v-for="item in fileImg.length">-->
                          <!--                              <img :src="previewImage" />-->
                          <!--                            </div>-->
                          <!--                          </a-carousel>-->
                          <img
                            alt="example"
                            style="width: 100%"
                            :src="previewImage"
                          />
                        </a-modal>
                      </div>
                    </template>
                  </a-form-item>
                </a-form>
              </div>
            </a-card>
          </DescriptionList>
          <Divider />
          <DescriptionList
            title="基本信息"
            size="large"
            style="margin-bottom: 24px;"
          >
            <a-card :bordered="false" style="margin-left: -16px">
              <div class="table-operator">
                <a-form layout="inline" :form="form">
                  <a-row :gutter="5" style="line-height: 4;">
                    <a-col :md="8" :sm="24" class="storeNewStoreLabelWidth">
                      <a-form-item label="开业日期">
                        <a-date-picker
                          style="width: 240px"
                          v-decorator="[
                            'basicInfo.openDate',
                            {
                              rules: [
                                {
                                  required: true,
                                  message: '请输入！'
                                }
                              ]
                            }
                          ]"
                        />
                      </a-form-item>
                    </a-col>
                    <a-col :md="16" :sm="24" class="storeNewStoreLabelWidth">
                      <a-form-item label="营业时间">
                        <a-time-picker
                          style="width: 110px"
                          v-decorator="[
                            'basicInfo.businessHourStartLg',
                            {
                              rules: [
                                {
                                  required: true,
                                  message: '请输入！'
                                }
                              ]
                            }
                          ]"
                        />
                        --
                        <a-time-picker
                          style="width: 110px"
                          v-decorator="[
                            'basicInfo.businessHourEndLg',
                            {
                              rules: [
                                {
                                  required: true,
                                  message: '请输入！'
                                }
                              ]
                            }
                          ]"
                        />
                      </a-form-item>
                    </a-col>
                    <a-col :md="24" :sm="24" class="storeNewStoreLabelWidth">
                      <a-form-item label="营业执照范围">
                        <a-select
                          mode="multiple"
                          style="min-width: 607px"
                          v-decorator="[
                            'basicInfo.bizLicenseScope',
                            {
                              rules: [
                                {
                                  required: true,
                                  message: '请输入！'
                                }
                              ]
                            }
                          ]"
                          placeholder="请选择"
                        >
                          <a-select-option
                            v-for="item in bizLicenseScope"
                            :key="item.id"
                          >
                            {{ item.name }}
                          </a-select-option>
                        </a-select>
                      </a-form-item>
                    </a-col>
                    <a-col :md="24" :sm="24" class="storeNewStoreLabelWidth2">
                      <a-form-item label="食品经营许可证范围">
                        <a-select
                          mode="multiple"
                          style="min-width: 565px"
                          v-decorator="[
                            'basicInfo.cateringLicenseScope',
                            {
                              rules: [
                                {
                                  required: true,
                                  message: '请选择！'
                                }
                              ]
                            }
                          ]"
                          placeholder="请选择"
                        >
                          <a-select-option
                            v-for="item in cateringLicenseScope"
                            :key="item.id"
                          >
                            {{ item.name }}
                          </a-select-option>
                        </a-select>
                      </a-form-item>
                    </a-col>
                  </a-row>
                </a-form>
              </div>
            </a-card>
          </DescriptionList>
          <Divider />
          <DescriptionList
            title="建筑信息"
            size="large"
            style="margin-bottom: 32px;"
          >
            <a-card :bordered="false" style="margin-left: -16px">
              <a-form layout="inline" :form="form">
                <a-row :gutter="5" style="line-height: 4;">
                  <a-col :md="8" :sm="24" class="storeNewStoreLabelWidth">
                    <a-form-item label="项目编号">
                      <a-input
                        style="width: 240px"
                        v-decorator="[
                          'facilityBuildingDTO.propertyCode',
                          {
                            rules: [
                              {
                                required: false,
                                message: '请输入！'
                              }
                            ]
                          }
                        ]"
                        placeholder="请输入"
                      />
                    </a-form-item>
                  </a-col>
                  <a-col :md="8" :sm="24" class="storeNewStoreLabelWidth">
                    <a-form-item label="门店设计面积">
                      <a-input
                        style="width: 240px"
                        v-decorator="[
                          'facilityBuildingDTO.nfa',
                          {
                            rules: [
                              {
                                pattern: /^[0-9]+([.]{1}[0-9]+){0,1}$/,
                                required: true,
                                message: '请输入数字！'
                              }
                            ]
                          }
                        ]"
                        placeholder="请输入                                    m²"
                      />
                    </a-form-item>
                  </a-col>
                  <a-col :md="8" :sm="24" class="storeNewStoreLabelWidth">
                    <a-form-item label="门店施工时间">
                      <a-range-picker
                        style="width: 240px"
                        v-decorator="[
                          'facilityBuildingDTO.constructionDate',
                          {
                            rules: [
                              {
                                required: true,
                                message: '请输入！'
                              }
                            ]
                          }
                        ]"
                      />
                    </a-form-item>
                  </a-col>
                  <a-col :md="8" :sm="24" class="storeNewStoreLabelWidth">
                    <a-form-item label="承租装修费">
                      <a-input
                        style="width: 240px"
                        v-decorator="[
                          'facilityBuildingDTO.constructionCost',
                          {
                            rules: [
                              {
                                pattern: /^[0-9]+([.]{1}[0-9]+){0,1}$/,
                                required: false,
                                message: '请输入数字！'
                              }
                            ]
                          }
                        ]"
                        placeholder="请输入                                        元"
                      />
                    </a-form-item>
                  </a-col>
                  <a-col :md="16" :sm="24" class="storeNewStoreLabelWidth">
                    <a-form-item label="承租扩容费">
                      <a-input
                        style="width: 240px"
                        v-decorator="[
                          'facilityBuildingDTO.engineeringUpgradeCost',
                          {
                            rules: [
                              {
                                pattern: /^[0-9]+([.]{1}[0-9]+){0,1}$/,
                                required: false,
                                message: '请输入数字！'
                              }
                            ]
                          }
                        ]"
                        placeholder="请输入                                    元"
                      />
                    </a-form-item>
                  </a-col>
                  <a-col :md="8" :sm="24" class="storeNewStoreLabelWidth2">
                    <a-form-item label="承租扩容前电费总量">
                      <a-input
                        style="width: 198px"
                        v-decorator="[
                          'facilityBuildingDTO.existingElectricity',
                          {
                            rules: [
                              {
                                pattern: /^[0-9]+([.]{1}[0-9]+){0,1}$/,
                                required: true,
                                message: '请输入数字！'
                              }
                            ]
                          }
                        ]"
                        placeholder="请输入                            Kw"
                      />
                    </a-form-item>
                  </a-col>
                  <a-col :md="8" :sm="24" class="storeNewStoreLabelWidth2">
                    <a-form-item label="承租扩容前排烟量">
                      <a-input
                        style="width: 198px"
                        v-decorator="[
                          'facilityBuildingDTO.existingExhaust',
                          {
                            rules: [
                              {
                                pattern: /^[0-9]+([.]{1}[0-9]+){0,1}$/,
                                required: true,
                                message: '请输入数字！'
                              }
                            ]
                          }
                        ]"
                        placeholder="请输入                         m²/h"
                      />
                    </a-form-item>
                  </a-col>
                  <a-col :md="8" :sm="24" class="storeNewStoreLabelWidth2">
                    <a-form-item label="承租扩容前燃气量">
                      <a-input
                        style="width: 198px"
                        v-decorator="[
                          'facilityBuildingDTO.existingGas',
                          {
                            rules: [
                              {
                                pattern: /^[0-9]+([.]{1}[0-9]+){0,1}$/,
                                required: true,
                                message: '请输入数字！'
                              }
                            ]
                          }
                        ]"
                        placeholder="请输入                            m²"
                      />
                    </a-form-item>
                  </a-col>
                  <a-col :md="8" :sm="24" class="storeNewStoreLabelWidth2">
                    <a-form-item label="承租扩容后电费总量">
                      <a-input
                        style="width: 198px"
                        v-decorator="[
                          'facilityBuildingDTO.postUpgradeElectricity',
                          {
                            rules: [
                              {
                                pattern: /^[0-9]+([.]{1}[0-9]+){0,1}$/,
                                required: true,
                                message: '请输入数字！'
                              }
                            ]
                          }
                        ]"
                        placeholder="请输入                            Kw"
                      />
                    </a-form-item>
                  </a-col>
                  <a-col :md="8" :sm="24" class="storeNewStoreLabelWidth2">
                    <a-form-item label="承租扩容后排烟量">
                      <a-input
                        style="width: 198px"
                        v-decorator="[
                          'facilityBuildingDTO.postUpgradeExhaust',
                          {
                            rules: [
                              {
                                pattern: /^[0-9]+([.]{1}[0-9]+){0,1}$/,
                                required: true,
                                message: '请输入数字！'
                              }
                            ]
                          }
                        ]"
                        placeholder="请输入                         m²/h"
                      />
                    </a-form-item>
                  </a-col>
                  <a-col :md="8" :sm="24" class="storeNewStoreLabelWidth2">
                    <a-form-item label="承租扩容后燃气量">
                      <a-input
                        style="width: 198px"
                        v-decorator="[
                          'facilityBuildingDTO.postUpgradeGas',
                          {
                            rules: [
                              {
                                pattern: /^[0-9]+([.]{1}[0-9]+){0,1}$/,
                                required: true,
                                message: '请输入数字！'
                              }
                            ]
                          }
                        ]"
                        placeholder="请输入                            m²"
                      />
                    </a-form-item>
                  </a-col>
                  <a-col :md="8" :sm="24" class="storeNewStoreLabelWidth">
                    <a-form-item label="工程平面图：">
                      <template>
                        <div
                          class="clearfix"
                          style="width: 70%;display: inline-block"
                        >
                          <a-upload
                            listType="picture-card"
                            :fileList="fileImg1"
                            accept=".png,.jpg"
                            withCredentials
                            name="multipartFile"
                            :action="this.uploadUrl()"
                            @preview="handlePreview1"
                            @change="handleChange1"
                          >
                            <div v-if="fileImg1.length < 1">
                              <a-icon type="plus" />
                              <div class="ant-upload-text">上传</div>
                            </div>
                          </a-upload>
                          <a-modal
                            :visible="previewVisible1"
                            :footer="null"
                            @cancel="handleCancel"
                          >
                            <img
                              alt="example"
                              style="width: 100%"
                              :src="previewImage"
                            />
                          </a-modal>
                        </div>
                      </template>
                    </a-form-item>
                  </a-col>
                  <a-col :md="8" :sm="24" class="storeNewStoreLabelWidth">
                    <a-form-item label="设计渲染图：">
                      <template>
                        <div
                          class="clearfix"
                          style="width: 70%;display: inline-block"
                        >
                          <a-upload
                            listType="picture-card"
                            :fileList="fileImg2"
                            accept=".png,.jpg"
                            withCredentials
                            name="multipartFile"
                            :action="this.uploadUrl()"
                            @preview="handlePreview2"
                            @change="handleChange2"
                          >
                            <div v-if="fileImg2.length < 1">
                              <a-icon type="plus" />
                              <div class="ant-upload-text">上传</div>
                            </div>
                          </a-upload>
                          <a-modal
                            :visible="previewVisible2"
                            :footer="null"
                            @cancel="handleCancel"
                          >
                            <img
                              alt="example"
                              style="width: 100%"
                              :src="previewImage"
                            />
                          </a-modal>
                        </div>
                      </template>
                    </a-form-item>
                  </a-col>
                  <a-col :md="8" :sm="24" class="storeNewStoreLabelWidth">
                    <a-form-item label="食品药监平面图：">
                      <template>
                        <div
                          class="clearfix"
                          style="width: 70%;display: inline-block"
                        >
                          <a-upload
                            listType="picture-card"
                            :fileList="fileImg3"
                            accept=".png,.jpg"
                            withCredentials
                            name="multipartFile"
                            :action="this.uploadUrl()"
                            @preview="handlePreview3"
                            @change="handleChange3"
                          >
                            <div v-if="fileImg3.length < 1">
                              <a-icon type="plus" />
                              <div class="ant-upload-text">上传</div>
                            </div>
                          </a-upload>
                          <a-modal
                            :visible="previewVisible3"
                            :footer="null"
                            @cancel="handleCancel"
                          >
                            <img
                              alt="example"
                              style="width: 100%"
                              :src="previewImage"
                            />
                          </a-modal>
                        </div>
                      </template>
                    </a-form-item>
                  </a-col>
                  <a-col
                    :md="24"
                    :sm="24"
                    class="storeNewStoreLabelWidth"
                    style="margin-top: 12px;height: 77px"
                  >
                    <a-form-item label="上传附件：">
                      <template>
                        <a-upload
                          withCredentials
                          name="multipartFile"
                          :action="this.uploadUrl()"
                          :defaultFileList="defaultFileList"
                          accept=".doc,.docx,.xlsx"
                          @change="exportFile"
                        >
                          <a-button> <a-icon type="upload" /> 上传 </a-button>
                        </a-upload>
                      </template>
                    </a-form-item>
                  </a-col>
                </a-row>
              </a-form>
            </a-card>
          </DescriptionList>
        </a-card>
      </div>
      <div v-if="current == 1">
        <a-card>
          <DescriptionList
            title="基本信息"
            size="large"
            style="margin-bottom: 32px;"
          >
            <a-card :bordered="false" class="top24">
              <div class="table-operator">
                <a-form layout="inline" :form="form">
                  <a-row :gutter="0" style="line-height: 4">
                    <a-col :md="8" :sm="24" class="storeNewStoreLabelWidth4">
                      <a-form-item label="房东主要联系人">
                        <a-input
                          style="width: 200px"
                          v-decorator="[
                            'facilityLeaseDTO.landLoad',
                            {
                              rules: [
                                {
                                  required: false,
                                  message: '请输入！'
                                }
                              ]
                            }
                          ]"
                          placeholder="请输入"
                        />
                      </a-form-item>
                    </a-col>
                    <a-col :md="8" :sm="24" class="storeNewStoreLabelWidth3">
                      <a-form-item label="房东物业管理联系人">
                        <a-input
                          style="width: 200px"
                          v-decorator="[
                            'facilityLeaseDTO.propertyManager',
                            {
                              rules: [
                                {
                                  required: false,
                                  message: '请输入！'
                                }
                              ]
                            }
                          ]"
                          placeholder="请输入"
                        />
                      </a-form-item>
                    </a-col>
                    <a-col :md="8" :sm="24" class="storeNewStoreLabelWidth5">
                      <a-form-item label="房东财务联系人">
                        <a-input
                          style="width: 200px"
                          v-decorator="[
                            'facilityLeaseDTO.financeManager',
                            {
                              rules: [
                                {
                                  required: false,
                                  message: '请输入！'
                                }
                              ]
                            }
                          ]"
                          placeholder="请输入"
                        />
                      </a-form-item>
                    </a-col>
                    <a-col :md="8" :sm="24" class="storeNewStoreLabelWidth4">
                      <a-form-item label="房东主要联系人电话">
                        <a-input
                          style="width: 200px"
                          v-decorator="[
                            'facilityLeaseDTO.llPhone',
                            {
                              rules: [
                                {
                                  pattern: /^1\d{10}$/,
                                  required: false,
                                  message: '请输入！'
                                }
                              ]
                            }
                          ]"
                          placeholder="请输入"
                        />
                      </a-form-item>
                    </a-col>
                    <a-col :md="8" :sm="24" class="storeNewStoreLabelWidth3">
                      <a-form-item label="房东物业管理联系人电话">
                        <a-input
                          style="width: 200px"
                          v-decorator="[
                            'facilityLeaseDTO.pmPhone',
                            {
                              rules: [
                                {
                                  pattern: /^1\d{10}$/,
                                  required: false,
                                  message: '请输入！'
                                }
                              ]
                            }
                          ]"
                          placeholder="请输入"
                        />
                      </a-form-item>
                    </a-col>
                    <a-col :md="8" :sm="24" class="storeNewStoreLabelWidth5">
                      <a-form-item label="房东财务电话">
                        <a-input
                          style="width: 200px"
                          v-decorator="[
                            'facilityLeaseDTO.financePhone',
                            {
                              rules: [
                                {
                                  pattern: /^1\d{10}$/,
                                  required: false,
                                  message: '请输入！'
                                }
                              ]
                            }
                          ]"
                          placeholder="请输入"
                        />
                      </a-form-item>
                    </a-col>
                  </a-row>
                </a-form>
              </div>
            </a-card>
          </DescriptionList>
          <Divider />
          <DescriptionList title="费用信息" size="large">
            <a-card :bordered="false" style="margin-left: -16px">
              <a-form layout="inline" :form="form">
                <a-row :gutter="5" style="line-height: 4">
                  <a-col :md="8" :sm="24">
                    <a-form-item label="承租电费">
                      <a-input
                        style="width: 240px"
                        v-decorator="[
                          'facilityLeaseDTO.lesseeElecPrice',
                          {
                            rules: [
                              {
                                pattern: /^[0-9]+([.]{1}[0-9]+){0,1}$/,
                                required: true,
                                message: '请输入！'
                              }
                            ]
                          }
                        ]"
                        placeholder="请输入"
                      />
                    </a-form-item>
                  </a-col>
                  <a-col :md="8" :sm="24">
                    <a-form-item label="承租水费">
                      <a-input
                        style="width: 240px"
                        v-decorator="[
                          'facilityLeaseDTO.lesseeWaterPrice',
                          {
                            rules: [
                              {
                                pattern: /^[0-9]+([.]{1}[0-9]+){0,1}$/,
                                required: true,
                                message: '请输入！'
                              }
                            ]
                          }
                        ]"
                        placeholder="请输入"
                      />
                    </a-form-item>
                  </a-col>
                  <a-col :md="8" :sm="24">
                    <a-form-item label="承租燃气">
                      <a-input
                        style="width: 240px"
                        v-decorator="[
                          'facilityLeaseDTO.lesseeGasPrice',
                          {
                            rules: [
                              {
                                pattern: /^[0-9]+([.]{1}[0-9]+){0,1}$/,
                                required: true,
                                message: '请输入！'
                              }
                            ]
                          }
                        ]"
                        placeholder="请输入"
                      />
                    </a-form-item>
                  </a-col>
                </a-row>
              </a-form>
            </a-card>
          </DescriptionList>
        </a-card>
      </div>
      <div v-if="current == 2">
        <a-card>
          <DescriptionList
            title="证照信息"
            size="large"
            style="margin-bottom: 32px;"
          >
            <a-card :bordered="false" class="top24">
              <a-form layout="inline" :form="form">
                <a-row :gutter="5" style="line-height: 4">
                  <a-col :md="8" :sm="24" class="storeNewStoreLabelWidth6">
                    <a-form-item label="门店公司名称">
                      <a-input
                        style="width: 240px"
                        v-decorator="[
                          'facilityCertificatesDTO.name',
                          {
                            rules: [
                              {
                                required: true,
                                message: '请输入！'
                              }
                            ]
                          }
                        ]"
                        placeholder="请输入"
                      />
                    </a-form-item>
                  </a-col>
                  <a-col :md="8" :sm="24" class="storeNewStoreLabelWidth6">
                    <a-form-item label="营业期限">
                      <a-range-picker
                        style="width: 240px"
                        v-decorator="[
                          'facilityCertificatesDTO.bizLicenseDate',
                          {
                            rules: [
                              {
                                required: true,
                                message: '请输入！'
                              }
                            ]
                          }
                        ]"
                      />
                    </a-form-item>
                  </a-col>
                  <a-col :md="8" :sm="24" class="storeNewStoreLabelWidth7">
                    <a-form-item label="统一社会信用代码">
                      <a-input
                        style="width: 212px"
                        v-decorator="[
                          'facilityCertificatesDTO.socialCode',
                          {
                            rules: [
                              {
                                required: true,
                                message: '请输入'
                              }
                            ]
                          }
                        ]"
                        placeholder="请输入"
                      />
                    </a-form-item>
                  </a-col>
                  <a-col :md="24" :sm="24" class="storeNewStoreLabelWidth8">
                    <a-form-item label="营业执照经营范围">
                      <a-select
                        style="width: 932px"
                        mode="multiple"
                        v-decorator="[
                          'facilityCertificatesDTO.bizLicenseScope',
                          {
                            rules: [
                              {
                                required: true,
                                message: '请选择!'
                              }
                            ]
                          }
                        ]"
                        placeholder="请选择"
                      >
                        <a-select-option
                          v-for="item in bizLicenseScope"
                          :key="item.id"
                        >
                          {{ item.name }}
                        </a-select-option>
                      </a-select>
                    </a-form-item>
                  </a-col>
                  <a-col :md="8" :sm="24" class="storeNewStoreLabelWidth8">
                    <a-form-item label="FDA食药监是否通过">
                      <a-select
                        style="width: 198px"
                        v-decorator="[
                          'facilityCertificatesDTO.fdaStatus',
                          {
                            rules: [
                              {
                                required: true,
                                message: '请选择！'
                              }
                            ]
                          }
                        ]"
                        placeholder="请选择"
                      >
                        <a-select-option v-for="item in yesorno" :key="item.id">
                          {{ item.name }}
                        </a-select-option>
                      </a-select>
                    </a-form-item>
                  </a-col>
                  <a-col :md="8" :sm="24" class="storeNewStoreLabelWidth8">
                    <a-form-item label="食品经营许可证日期">
                      <a-range-picker
                        style="width: 198px"
                        v-decorator="[
                          'facilityCertificatesDTO.foodCertificateDate',
                          {
                            rules: [
                              {
                                required: true,
                                message: '请输入'
                              }
                            ]
                          }
                        ]"
                      />
                    </a-form-item>
                  </a-col>
                  <a-col :md="8" :sm="24" class="storeNewStoreLabelWidth8">
                    <a-form-item label="食品经营许可证编码">
                      <a-input
                        style="width: 198px"
                        v-decorator="[
                          'facilityCertificatesDTO.foodCertificate',
                          {
                            rules: [
                              {
                                required: true,
                                message: '请输入！'
                              }
                            ]
                          }
                        ]"
                        placeholder="请输入"
                      />
                    </a-form-item>
                  </a-col>
                  <a-col :md="24" :sm="24" class="storeNewStoreLabelWidth8">
                    <a-form-item label="食品经营许可证范围">
                      <a-select
                        style="width: 932px"
                        mode="multiple"
                        v-decorator="[
                          'facilityCertificatesDTO.cateringLicenseScope',
                          {
                            rules: [
                              {
                                required: true,
                                message: '请选择!'
                              }
                            ]
                          }
                        ]"
                        placeholder="请选择"
                      >
                        <a-select-option
                          v-for="item in cateringLicenseScope"
                          :key="item.id"
                        >
                          {{ item.name }}
                        </a-select-option>
                      </a-select>
                    </a-form-item>
                  </a-col>
                  <a-col :md="8" :sm="24" class="storeNewStoreLabelWidth8">
                    <a-form-item label="FSB消防验收通过">
                      <a-select
                        style="width: 198px"
                        v-decorator="[
                          'facilityCertificatesDTO.fsbStatus',
                          {
                            rules: [
                              {
                                required: true,
                                message: '请选择！'
                              }
                            ]
                          }
                        ]"
                        placeholder="请选择"
                      >
                        <a-select-option v-for="item in yesorno" :key="item.id">
                          {{ item.name }}
                        </a-select-option>
                      </a-select>
                    </a-form-item>
                  </a-col>
                  <a-col :md="8" :sm="24" class="storeNewStoreLabelWidth8">
                    <a-form-item label="消防许可证通过日期">
                      <a-date-picker
                        style="width: 198px"
                        v-decorator="[
                          'facilityCertificatesDTO.fsbPassDate',
                          {
                            rules: [
                              {
                                required: true,
                                message: '请选择日期！'
                              }
                            ]
                          }
                        ]"
                      />
                    </a-form-item>
                  </a-col>
                  <a-col :md="8" :sm="24" class="storeNewStoreLabelWidth7">
                    <a-form-item label="消防许可证编号">
                      <a-input
                        style="width: 212px"
                        v-decorator="[
                          'facilityCertificatesDTO.fsbCode',
                          {
                            rules: [
                              {
                                required: true,
                                message: '请输入！'
                              }
                            ]
                          }
                        ]"
                        placeholder="请输入"
                      />
                    </a-form-item>
                  </a-col>
                </a-row>
              </a-form>
            </a-card>
          </DescriptionList>
          <Divider />
          <DescriptionList size="large" style="margin-top: -24px;">
            <a-card :bordered="false" style="margin-left: -16px">
              <a-form layout="inline" :form="form">
                <a-row :gutter="5" style="line-height: 4;padding-bottom: 24px">
                  <a-col :md="8" :sm="24" class="storeNewStoreLabelWidth6">
                    <a-form-item label="营业执照图片：">
                      <template>
                        <div
                          class="clearfix"
                          style="width: 70%;display: inline-block"
                        >
                          <a-upload
                            listType="picture-card"
                            :fileList="fileImg5"
                            accept=".png,.jpg"
                            withCredentials
                            name="multipartFile"
                            :action="this.uploadUrl()"
                            @preview="handlePreview5"
                            @change="handleChange5"
                          >
                            <div v-if="fileImg5.length < 1">
                              <a-icon type="plus" />
                              <div class="ant-upload-text">上传</div>
                            </div>
                          </a-upload>
                          <a-modal
                            :visible="previewVisible5"
                            :footer="null"
                            @cancel="handleCancel"
                          >
                            <img
                              alt="example"
                              style="width: 100%"
                              :src="previewImage"
                            />
                          </a-modal>
                        </div>
                      </template>
                    </a-form-item>
                  </a-col>
                  <a-col :md="8" :sm="24" class="storeNewStoreLabelWidth8">
                    <a-form-item label="食品经营许可证照片：">
                      <template>
                        <div
                          class="clearfix"
                          style="width: 70%;display: inline-block"
                        >
                          <a-upload
                            listType="picture-card"
                            :fileList="fileImg6"
                            accept=".png,.jpg"
                            withCredentials
                            name="multipartFile"
                            :action="this.uploadUrl()"
                            @preview="handlePreview6"
                            @change="handleChange6"
                          >
                            <div v-if="fileImg6.length < 1">
                              <a-icon type="plus" />
                              <div class="ant-upload-text">上传</div>
                            </div>
                          </a-upload>
                          <a-modal
                            :visible="previewVisible6"
                            :footer="null"
                            @cancel="handleCancel"
                          >
                            <img
                              alt="example"
                              style="width: 100%"
                              :src="previewImage"
                            />
                          </a-modal>
                        </div>
                      </template>
                    </a-form-item>
                  </a-col>
                  <a-col :md="8" :sm="24" class="storeNewStoreLabelWidth7">
                    <a-form-item label="消防许可证编号：">
                      <template>
                        <div
                          class="clearfix"
                          style="width: 70%;display: inline-block"
                        >
                          <a-upload
                            listType="picture-card"
                            :fileList="fileImg7"
                            accept=".png,.jpg"
                            withCredentials
                            name="multipartFile"
                            :action="this.uploadUrl()"
                            @preview="handlePreview7"
                            @change="handleChange7"
                          >
                            <div v-if="fileImg7.length < 1">
                              <a-icon type="plus" />
                              <div class="ant-upload-text">上传</div>
                            </div>
                          </a-upload>
                          <a-modal
                            :visible="previewVisible7"
                            :footer="null"
                            @cancel="handleCancel"
                          >
                            <img
                              alt="example"
                              style="width: 100%"
                              :src="previewImage"
                            />
                          </a-modal>
                        </div>
                      </template>
                    </a-form-item>
                  </a-col>
                </a-row>
              </a-form>
            </a-card>
          </DescriptionList>
        </a-card>
      </div>
      <div v-if="current == 3">
        <a-card>
          <DescriptionList title="费用信息" size="large">
            <a-card
              :bordered="false"
              style="margin-left: -16px;margin-bottom: -18px"
              class="storeNewStoreLabelWidth9"
            >
              <a-form layout="inline" :form="form">
                <a-row :gutter="5" style="line-height: 4">
                  <a-col :md="8" :sm="24">
                    <a-form-item label="出租电费">
                      <a-input
                        style="width: 240px"
                        v-decorator="[
                          'facilityCostDTO.elePrice',
                          {
                            rules: [
                              {
                                pattern: /^[0-9]+([.]{1}[0-9]+){0,1}$/,
                                required: true,
                                message: '请输入！'
                              }
                            ]
                          }
                        ]"
                        placeholder="请输入"
                      >
                      </a-input>
                    </a-form-item>
                  </a-col>
                  <a-col :md="8" :sm="24">
                    <a-form-item label="出租水费">
                      <a-input
                        style="width: 240px"
                        v-decorator="[
                          'facilityCostDTO.waterPrice',
                          {
                            rules: [
                              {
                                pattern: /^[0-9]+([.]{1}[0-9]+){0,1}$/,
                                required: true,
                                message: '请输入！'
                              }
                            ]
                          }
                        ]"
                        placeholder="请输入"
                      >
                      </a-input>
                    </a-form-item>
                  </a-col>
                  <a-col :md="8" :sm="24">
                    <a-form-item label="出租燃气费">
                      <a-input
                        style="width: 240px"
                        v-decorator="[
                          'facilityCostDTO.gasPrice',
                          {
                            rules: [
                              {
                                pattern: /^[0-9]+([.]{1}[0-9]+){0,1}$/,
                                required: true,
                                message: '请输入！'
                              }
                            ]
                          }
                        ]"
                        placeholder="请输入"
                      >
                      </a-input>
                    </a-form-item>
                  </a-col>
                  <!-- <a-col :md="8" :sm="24">
                    <a-form-item label="消杀费">
                      <a-input
                        style="width: 240px"
                        v-decorator="[
                          'facilityCostDTO.disinfectionPrice',
                          {
                            rules: [
                              {
                                pattern: /^[0-9]+([.]{1}[0-9]+){0,1}$/,
                                required: true,
                                message: '请输入！'
                              }
                            ]
                          }
                        ]"
                        placeholder="请输入"
                      >
                      </a-input>
                    </a-form-item>
                  </a-col> -->
                  <!-- <a-col :md="8" :sm="24">
                    <a-form-item label="电表倍率">
                      <a-input
                        style="width: 240px"
                        v-decorator="[
                          'facilityCostDTO.elecRate',
                          {
                            rules: [
                              {
                                pattern: /^[0-9]+([.]{1}[0-9]+){0,1}$/,
                                required: true,
                                message: '请输入！'
                              }
                            ]
                          }
                        ]"
                        placeholder="请输入"
                      >
                      </a-input>
                    </a-form-item>
                  </a-col> -->
                  <!-- <a-col :md="8" :sm="24">
                    <a-form-item label="水表倍率">
                      <a-input
                        style="width: 240px"
                        v-decorator="[
                          'facilityCostDTO.waterRate',
                          {
                            rules: [
                              {
                                pattern: /^[0-9]+([.]{1}[0-9]+){0,1}$/,
                                required: true,
                                message: '请输入！'
                              }
                            ]
                          }
                        ]"
                        placeholder="请输入"
                      >
                      </a-input>
                    </a-form-item>
                  </a-col> -->
                  <!-- <a-col :md="8" :sm="24">
                    <a-form-item label="油烟清洗费">
                      <a-input
                        style="width: 240px"
                        v-decorator="[
                          'facilityCostDTO.lampblackCleanPrice',
                          {
                            rules: [
                              {
                                pattern: /^[0-9]+([.]{1}[0-9]+){0,1}$/,
                                required: true,
                                message: '请输入！'
                              }
                            ]
                          }
                        ]"
                        placeholder="请输入"
                      >
                      </a-input>
                    </a-form-item>
                  </a-col> -->
                  <!-- <a-col :md="8" :sm="24">
                    <a-form-item label="管道疏通费">
                      <a-input
                        style="width: 240px"
                        v-decorator="[
                          'facilityCostDTO.rushPipePrice',
                          {
                            rules: [
                              {
                                pattern: /^[0-9]+([.]{1}[0-9]+){0,1}$/,
                                required: true,
                                message: '请输入！'
                              }
                            ]
                          }
                        ]"
                        placeholder="请输入"
                      >
                      </a-input>
                    </a-form-item>
                  </a-col> -->
                  <!-- <a-col :md="8" :sm="24">
                    <a-form-item label="垃圾清运费">
                      <a-input
                        style="width: 240px"
                        v-decorator="[
                          'facilityCostDTO.garbageDisposalPrice',
                          {
                            rules: [
                              {
                                pattern: /^[0-9]+([.]{1}[0-9]+){0,1}$/,
                                required: true,
                                message: '请输入！'
                              }
                            ]
                          }
                        ]"
                        placeholder="请输入"
                      >
                      </a-input>
                    </a-form-item>
                  </a-col> -->
                  <!-- <a-col :md="8" :sm="24">
                    <a-form-item label="网络费">
                      <a-input
                        style="width: 240px"
                        v-decorator="[
                          'facilityCostDTO.networkPrice',
                          {
                            rules: [
                              {
                                pattern: /^[0-9]+([.]{1}[0-9]+){0,1}$/,
                                required: true,
                                message: '请输入！'
                              }
                            ]
                          }
                        ]"
                        placeholder="请输入"
                      >
                      </a-input>
                    </a-form-item>
                  </a-col> -->
                  <!-- <a-col :md="8" :sm="24">
                    <a-form-item label="食堂管理费">
                      <a-input
                        style="width: 240px"
                        v-decorator="[
                          'facilityCostDTO.canteenManagePrice',
                          {
                            rules: [
                              {
                                pattern: /^[0-9]+([.]{1}[0-9]+){0,1}$/,
                                required: true,
                                message: '请输入！'
                              }
                            ]
                          }
                        ]"
                        placeholder="请输入"
                      >
                      </a-input>
                    </a-form-item>
                  </a-col> -->
                </a-row>
              </a-form>
            </a-card>
            <Divider></Divider>
            <a-card
              :bordered="false"
              style="margin-top: -24px;margin-left: -16px"
            >
              <a-form layout="inline" :form="form">
                <a-row
                  :gutter="5"
                  style="line-height: 4"
                  class="storeNewStoreLabelWidth10"
                >
                  <a-col :md="8" :sm="24">
                    <a-form-item label="是否使用空调">
                      <a-select
                        style="width: 198px"
                        v-decorator="[
                          'facilityCostDTO.ac',
                          {
                            rules: [
                              {
                                required: true,
                                message: '请输入！'
                              }
                            ]
                          }
                        ]"
                        placeholder="请选择"
                      >
                        <a-select-option v-for="item in yesorno" :key="item.id">
                          {{ item.name }}
                        </a-select-option>
                      </a-select>
                    </a-form-item>
                  </a-col>
                  <a-col :md="8" :sm="24">
                    <a-form-item label="是否使用燃气">
                      <a-select
                        style="width: 198px"
                        v-decorator="[
                          'facilityCostDTO.gas',
                          {
                            rules: [
                              {
                                required: true,
                                message: '请输入！'
                              }
                            ]
                          }
                        ]"
                        placeholder="请选择"
                      >
                        <a-select-option v-for="item in yesorno" :key="item.id">
                          {{ item.name }}
                        </a-select-option>
                      </a-select>
                    </a-form-item>
                  </a-col>
                  <a-col :md="8" :sm="24">
                    <a-form-item label="是否使用智能电表">
                      <a-select
                        style="width: 184px"
                        v-decorator="[
                          'facilityCostDTO.smartMeter',
                          {
                            rules: [
                              {
                                required: true,
                                message: '请输入！'
                              }
                            ]
                          }
                        ]"
                        placeholder="请选择"
                      >
                        <a-select-option v-for="item in yesorno" :key="item.id">
                          {{ item.name }}
                        </a-select-option>
                      </a-select>
                    </a-form-item>
                  </a-col>
                  <a-col :md="8" :sm="24">
                    <a-form-item label="电是否用物业卡充值">
                      <a-select
                        style="width: 198px"
                        v-decorator="[
                          'facilityCostDTO.electricPrepaid',
                          {
                            rules: [
                              {
                                required: true,
                                message: '请输入！'
                              }
                            ]
                          }
                        ]"
                        placeholder="请选择"
                      >
                        <a-select-option v-for="item in yesorno" :key="item.id">
                          {{ item.name }}
                        </a-select-option>
                      </a-select>
                    </a-form-item>
                  </a-col>
                  <a-col :md="8" :sm="24">
                    <a-form-item label="水是否用物业卡充值">
                      <a-select
                        style="width: 198px"
                        v-decorator="[
                          'facilityCostDTO.waterPrepaid',
                          {
                            rules: [
                              {
                                required: true,
                                message: '请输入！'
                              }
                            ]
                          }
                        ]"
                        placeholder="请选择"
                      >
                        <a-select-option v-for="item in yesorno" :key="item.id">
                          {{ item.name }}
                        </a-select-option>
                      </a-select>
                    </a-form-item>
                  </a-col>
                  <a-col :md="8" :sm="24">
                    <a-form-item label="燃气是否用物业卡充值">
                      <a-select
                        style="width: 184px"
                        v-decorator="[
                          'facilityCostDTO.gasPrepaid',
                          {
                            rules: [
                              {
                                required: true,
                                message: '请输入！'
                              }
                            ]
                          }
                        ]"
                        placeholder="请选择"
                      >
                        <a-select-option v-for="item in yesorno" :key="item.id">
                          {{ item.name }}
                        </a-select-option>
                      </a-select>
                    </a-form-item>
                  </a-col>
                </a-row>
              </a-form>
            </a-card>
          </DescriptionList>
        </a-card>
      </div>
      <div style="margin-top: 8px">
        <a-card>
          <div class="steps-action">
            <a-button @click="cancel">
              取消
            </a-button>
            <a-button v-if="current > 0" style="margin-left: 8px" @click="prev">
              上一步
            </a-button>
            <a-button
              v-if="current !== steps.length - 1"
              style="margin-left: 8px"
              type="primary"
              @click="next"
            >
              下一步
            </a-button>
            <a-button
              v-if="current == steps.length - 1"
              type="primary"
              style="margin-left: 8px"
              @click="conserve"
            >
              保存
            </a-button>
          </div>
        </a-card>
      </div>
    </div>
  </div>
</template>

<script>
import { modal } from "ant-design-vue";
  
import DescriptionList from "@/components/DescriptionList";
import { Divider } from "ant-design-vue";
import Bus from "@/bus";
import axios from "axios";
import { defaultPagination } from "@/utils/pagination";
import { mapActions, mapGetters } from "vuex";
import { GET_USER_CITIES } from "@/store/modules/actions.type";

export default {
  name: "storeListEdit",
  components: { DescriptionList, Divider },
  data() {
    return {
      url: "",
      defaultFileList: [],
      status: [
        {
          name: "营业中"
        },
        {
          name: "关店"
        }
      ],
      //是否
      yesorno: [
        {
          id: "Y",
          name: "是"
        },
        {
          id: "N",
          name: "否"
        }
      ],
      labelCol: {
        xs: { span: 24 },
        sm: { span: 7 }
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 13 }
      },
      rangeConfig: {
        rules: [
          { type: "array", required: true, message: "Please select time!" }
        ]
      },
      form: this.$form.createForm(this),
      queryParam: {
        name: ""
      },
      data: [],
      storeNew: true,
      confirmLoading: false,
      pagination: defaultPagination(() => {}),
      //表头
      columns: [
        {
          title: "成员",
          dataIndex: "age",
          key: ""
        },
        {
          title: "工号",
          dataIndex: "name",
          key: ""
        },
        {
          title: "职位",
          dataIndex: "",
          key: ""
        },
        {
          title: "操作",
          dataIndex: "action",
          key: "action",
          scopedSlots: { customRender: "action" }
        }
      ],
      visible: false,
      staffEdit: false,
      previewVisible: false,
      previewVisible1: false,
      previewVisible2: false,
      previewVisible3: false,
      previewVisible5: false,
      previewVisible6: false,
      previewVisible7: false,
      previewImage: "",
      uploading: false,
      fileImg: [],
      fileImg1: [],
      fileImg2: [],
      fileImg3: [],
      fileImg4: [],
      fileImg5: [],
      fileImg6: [],
      fileImg7: [],
      fileList: [
        // {
        //   uid: "-1",
        //   name: "xxx.png",
        //   status: "done",
        //   url:
        //     "https://zos.alipayobjects.com/rmsportal/jkjgkEfvpUPVyRjUImniVslZfWPnJuuZ.png"
        // }
      ],
      current: 0,
      steps: [
        {
          title: "基础信息"
          // content: 'First-content',
        },
        {
          title: "承租信息"
          // content: 'Second-content',
        },
        {
          title: "证照信息"
          // content: 'Last-content',
        },
        {
          title: "费用信息"
        }
      ],
      basicInfo: {
        bizLicenseScope: [],
        businessHourStart: "",
        cateringLicenseScope: [],
        businessHourStartLg: "",
        businessHourEndLg: ""
      },
      //门店状态
      storeStatus: [
        {
          id: 1,
          name: "已签约"
        },
        {
          id: 2,
          name: "施工中"
        },
        {
          id: 3,
          name: "已开业"
        },
        {
          id: 4,
          name: "停业中"
        },
        {
          id: 5,
          name: "已关店"
        }
      ],
      //门店类型
      storeType: [
        {
          id: 1,
          name: "纯外卖"
        },
        {
          id: 2,
          name: "外卖+堂食"
        },
        {
          id: 3,
          name: "外卖+大餐厅商户"
        },
        {
          id: 4,
          name: "外卖+高端食堂"
        },
        {
          id: 5,
          name: "外卖+食堂"
        },
        {
          id: 6,
          name: "其他"
        }
      ],
      //门店性质
      storeNature: [
        {
          id: 1,
          name: "自建"
        },
        {
          id: 2,
          name: "美广收购"
        },
        {
          id: 3,
          name: "承包经营"
        }
      ],
      //营业执照经营范围
      bizLicenseScope: [
        {
          id: 1,
          name: "餐饮企业管理"
        },
        {
          id: 2,
          name: "会务服务"
        },
        {
          id: 3,
          name: "展览展示服务"
        },
        {
          id: 4,
          name: "餐饮服务"
        },
        {
          id: 5,
          name: "清洁服务"
        },
        {
          id: 6,
          name: "礼仪服务"
        }
      ],
      //食品经营许可证范围
      cateringLicenseScope: [
        {
          id: "1",
          name: "热食类食品制售"
        },
        {
          id: "2",
          name: "冷食类食品制售"
        },
        {
          id: "3",
          name: "生食类食品制售（即生食类食品）"
        },
        {
          id: "4",
          name: "自制饮品"
        },
        {
          id: "5",
          name: "半成品"
        },
        {
          id: "6",
          name: "预包装"
        }
      ],
      //所属厨房
      brandNameList: [
        // { id: 1, name: "吉刻联盟" },
        // { id: 2, name: "闪电厨房" },
        // { id: 3, name: "食秀坊" },
        // { id: 4, name: "橘猫" },
        // { id: 5, name: "海星微厨" },
        // { id: 6, name: "美食门" }
      ],
      facilityBuildingDTO: {
        annexMedias: [],
        designRenderingMedias: [],
        fdaFloorPlanMedias: [],
        floorPlanMedias: []
      },
      facilityCertificatesDTO: {
        bizLicensePhoto: [],
        foodCertificatePhoto: [],
        fsbPhoto: []
      },
      record1: "",
      record2: "",
      record3: "",
      record0: "",
      headers: {
        authorization: "authorization-text"
      },
      facilityMedias: []
      // client: [],
    };
  },
  computed: {
    ...mapGetters("cities", ["userCities"])
  },
  created() {
    Bus.$on("getTarget", target => {
      this.$router.push({
        path: "/store/storeList"
      });
    });
    this.getUserCities();
    this.getKitchenList();
  },
  methods: {
    ...mapActions("cities", [GET_USER_CITIES]),
    // 根据城市获取厨房品牌
    getKitchenList(val) {
      axios({
        url:   this.$baseUrl + "facilityBrand/select",
        method: "GET",
        params: {
          cityId: val
        }
      }).then(res => {
        if (res.data.rows) {
          if (res.data.rows) {
            this.brandNameList = res.data.rows;
            this.brandNameList.forEach(item => {
              item.code = Number(item.code);
            });
          }
        }
      });
    },
    // 城市选择变动时触发
    selectCity(val) {
      this.brandNameList = [];
      this.getKitchenList(val);
      const {
        form: { setFieldsValue }
      } = this;
      setFieldsValue({
        facilityBrand: ""
      });
    },
    uploadUrl() {
      return   this.$baseUrl + "upload/ossFile";
    },
    next() {
      const {
        form: { validateFields }
      } = this;
      validateFields((errors, values) => {
        if (!errors) {
          if (this.current == 0) {
            this.record0 = values;
            this.record0.basicInfo.businessHourStartLg =
              new Date(this.record0.basicInfo.businessHourStartLg).getTime() ||
              "";
            this.record0.basicInfo.businessHourEndLg =
              new Date(this.record0.basicInfo.businessHourEndLg).getTime() ||
              "";
            console.log("this.record0", this.record0);
            this.record0.facilityBuildingDTO.constructionEndDate =
              values.facilityBuildingDTO.constructionDate[1];
            this.record0.facilityBuildingDTO.constructionStartDate =
              values.facilityBuildingDTO.constructionDate[0];

            // console.log("values", values);
          }
          if (this.current == 1) {
            this.record1 = values;
            // console.log("values", values);
          }
          if (this.current == 2) {
            this.record2 = values;
            this.record2.facilityCertificatesDTO.bizLicenseStartDate =
              values.facilityCertificatesDTO.bizLicenseDate[0];
            this.record2.facilityCertificatesDTO.bizLicenseEndDate =
              values.facilityCertificatesDTO.bizLicenseDate[1];
            this.record2.facilityCertificatesDTO.foodCertificateEndDate =
              values.facilityCertificatesDTO.foodCertificateDate[1];
            this.record2.facilityCertificatesDTO.foodCertificateStartDate =
              values.facilityCertificatesDTO.foodCertificateDate[0];
            console.log("values", values);
          }
          if (this.current == 3) {
            this.record3 = values;
            console.log("values", values);
          }
          this.current++;
        } else {
          console.log("values", values);
        }
      });
    },
    prev() {
      this.current--;
    },
    exportFile({ fileList }) {
      fileList.forEach((item, index) => {
        if (item.response) {
          console.log(item.response.obj);
          this.facilityBuildingDTO.annexMedias = this.ToChangeObj(
            item.response.obj
          );
        }
        if (item.status == "error") {
          fileList.splice(index, 1);
          this.$message.error("上传失败！");
          return;
        }
      });
      this.defaultFileList = fileList;
    },
    onChange(time) {
      console.log(time);
      this.value = time;
    },
    handlePreview(file) {
      // alert(file);
      this.previewImage = file.url || file.thumbUrl;
      this.previewVisible = true;
    },
    handlePreview1(file) {
      this.previewImage = file.url || file.thumbUrl;
      this.previewVisible1 = true;
    },
    handlePreview2(file) {
      this.previewImage = file.url || file.thumbUrl;
      this.previewVisible2 = true;
    },
    handlePreview3(file) {
      this.previewImage = file.url || file.thumbUrl;
      this.previewVisible3 = true;
    },
    handlePreview5(file) {
      this.previewImage = file.url || file.thumbUrl;
      this.previewVisible5 = true;
    },
    handlePreview6(file) {
      this.previewImage = file.url || file.thumbUrl;
      this.previewVisible6 = true;
    },
    handlePreview7(file) {
      this.previewImage = file.url || file.thumbUrl;
      this.previewVisible7 = true;
    },
    handleCancel() {
      this.previewVisible = false;
      this.previewVisible1 = false;
      this.previewVisible2 = false;
      this.previewVisible3 = false;
      this.previewVisible5 = false;
      this.previewVisible6 = false;
      this.previewVisible7 = false;
      this.visible = false;
      this.staffEdit = false;
    },
    customRequest(file) {
      console.log(file);
    },
    customImg(file) {
      console.log("err", file);
    },
    ToChangeObj1(val) {
      let c = {};
      c.url = val;
      c.type = 1;
      return c;
    },
    handleChange({ fileList }) {
      if (fileList.length >= 1) {
        this.facilityMedias = [];
        fileList.forEach((item, index) => {
          if (item.response) {
            this.facilityMedias[index] = this.ToChangeObj1(item.response.obj);
            console.log(this.facilityMedias);
          }
          if (item.status == "error") {
            fileList.splice(index, 1);
            this.$message.error("上传失败！");
            return;
          }
        });
      } else {
        this.facilityMedias = [];
      }
      this.fileImg = fileList;
      // console.log("this.fileImg", fileList);
    },
    handleChange1({ fileList }) {
      if (fileList.length >= 1) {
        fileList.forEach((item, index) => {
          if (item.response) {
            console.log(item.response.obj);
            this.facilityBuildingDTO.floorPlanMedias = this.ToChangeObj(
              item.response.obj
            );
          }
          if (item.status == "error") {
            fileList.splice(index, 1);
            this.$message.error("上传失败！");
            return;
          }
        });
      } else {
        this.facilityBuildingDTO.floorPlanMedias = [];
      }

      this.fileImg1 = fileList;
    },
    handleChange2({ fileList }) {
      if (fileList.length >= 1) {
        fileList.forEach((item, index) => {
          if (item.response) {
            console.log(item.response.obj);
            this.facilityBuildingDTO.designRenderingMedias = this.ToChangeObj(
              item.response.obj
            );
          }
          if (item.status == "error") {
            fileList.splice(index, 1);
            this.$message.error("上传失败！");
            return;
          }
        });
      } else {
        this.facilityBuildingDTO.designRenderingMedias = [];
      }
      this.fileImg2 = fileList;
    },
    handleChange3({ fileList }) {
      if (fileList.length >= 1) {
        fileList.forEach((item, index) => {
          if (item.response) {
            console.log(item.response.obj);
            this.facilityBuildingDTO.fdaFloorPlanMedias = this.ToChangeObj(
              item.response.obj
            );
          }
          if (item.status == "error") {
            fileList.splice(index, 1);
            this.$message.error("上传失败！");
            return;
          }
        });
      } else {
        this.facilityBuildingDTO.fdaFloorPlanMedias = [];
      }
      this.fileImg3 = fileList;
    },
    handleChange5({ fileList }) {
      if (fileList.length >= 1) {
        fileList.forEach((item, index) => {
          if (item.response) {
            console.log(item.response.obj);
            this.facilityCertificatesDTO.bizLicensePhoto = this.ToChangeObj(
              item.response.obj
            );
          }
          if (item.status == "error") {
            fileList.splice(index, 1);
            this.$message.error("上传失败！");
            return;
          }
        });
      } else {
        this.facilityCertificatesDTO.bizLicensePhoto = [];
      }
      this.fileImg5 = fileList;
    },
    handleChange6({ fileList }) {
      if (fileList.length >= 1) {
        fileList.forEach((item, index) => {
          if (item.response) {
            console.log(item.response.obj);
            this.facilityCertificatesDTO.foodCertificatePhoto = this.ToChangeObj(
              item.response.obj
            );
          }
          if (item.status == "error") {
            fileList.splice(index, 1);
            this.$message.error("上传失败！");
            return;
          }
        });
      } else {
        this.facilityCertificatesDTO.foodCertificatePhoto = [];
      }
      this.fileImg6 = fileList;
    },
    handleChange7({ fileList }) {
      if (fileList.length >= 1) {
        fileList.forEach((item, index) => {
          if (item.response) {
            console.log(item.response.obj);
            this.facilityCertificatesDTO.fsbPhoto = this.ToChangeObj(
              item.response.obj
            );
          }
          if (item.status == "error") {
            fileList.splice(index, 1);
            this.$message.error("上传失败！");
            return;
          }
        });
      } else {
        this.facilityCertificatesDTO.fsbPhoto = [];
      }
      this.fileImg7 = fileList;
    },
    handleSelectChange() {},
    //新建门店
    conserve() {
      let that = this;
      const {
        form: { validateFields }
      } = this;
      validateFields((errors, values) => {
        if (!errors) {
          this.record3 = values;
          // console.log('values',this.record3);
        } else {
          console.log("err");
        }
      });
      modal.confirm({
        title: "是否需要保存?",
        onOk() {
          that.facilityCreate();
          return new Promise((resolve, reject) => {
            setTimeout(Math.random() > 0.5 ? resolve : reject, 1000);
          }).catch(() => console.log("Oops errors!"));
        },
        onCancel() {}
      });
    },
    ToChangeObj(val) {
      let a = [];
      let c = {};
      c.url = val;
      c.type = 1;
      a.push(c);
      return a;
    },
    facilityCreate() {
      Object.assign(this.record0.facilityBuildingDTO, this.facilityBuildingDTO);
      Object.assign(
        this.record2.facilityCertificatesDTO,
        this.facilityCertificatesDTO
      );
      // this.record0.facilityBuildingDTO.push(this.facilityBuildingDTO);
      // this.record2.push(this.facilityCertificatesDTO);
      axios({
        url:   this.$baseUrl + "facility/create",
        method: "POST",
        withCredentials: true,
        data: {
          ...this.record0,
          step: 1,
          facilityCostDTO: this.record3.facilityCostDTO,
          facilityCertificatesDTO: this.record2.facilityCertificatesDTO,
          facilityLeaseDTO: this.record1.facilityLeaseDTO,
          facilityMedias: this.facilityMedias
          // ...this.facilityBuildingDTO,
          // ...this.facilityCertificatesDTO
        }
      }).then(res => {
        if (res.data.returnCode == 200) {
          this.$message.success("门店已建立！");
        } else {
          this.$message.error(`${res.data.returnMsg}`);
        }
        this.$router.push({
          path: "/store/storeList"
        });
      });
    },
    cancel() {
      let that = this;
      modal.confirm({
        title: "确定要放弃新建门店吗?",
        content: "该操作无法撤回，你还要继续吗？",
        onOk() {
          that.$router.push({ path: "/store/storeList" });
          return new Promise((resolve, reject) => {
            setTimeout(Math.random() > 0.5 ? resolve : reject, 1000);
          }).catch(() => console.log("Oops errors!"));
        },
        onCancel() {}
      });
    },
    //新建员工
    addStaff() {
      this.visible = true;
    },
    handleSubmit() {
      const {
        form: { validateFields }
      } = this;
      this.confirmLoading = true;
      validateFields((errors, values) => {
        if (!errors) {
          axios({
            url:   this.$baseUrl + "",
            method: "",
            withCredentials: true
          }).then(res => {
            if (res.data.success) {
              this.$message.success("员工已建立！");
            } else {
              this.$message.error(`${res.data.returnMsg}`);
            }
          });
          // setTimeout(() => {
          this.visible = false;
          this.confirmLoading = false;
          this.$emit("ok", values);
          // }, 1500);
          // this.selectList();
        } else {
          this.confirmLoading = false;
        }
      });
    },
    //编辑员工
    editStaff(record) {
      this.record = record;
      this.staffEdit = true;
    },
    handleStaffEdit(record) {
      const {
        form: { validateFields }
      } = this;
      this.confirmLoading = true;
      validateFields((errors, values) => {
        if (!errors) {
          axios({
            url:   this.$baseUrl + "",
            method: "",
            withCredentials: true
          }).then(res => {
            if (res.data.success) {
              this.$message.success("员工已编辑！");
            } else {
              this.$message.error(`${res.data.returnMsg}`);
            }
          });
          // setTimeout(() => {
          this.staffEdit = false;
          this.confirmLoading = false;
          this.$emit("ok", values);
          // }, 1500);
        } else {
          this.confirmLoading = false;
        }
      });
    },
    //删除员工
    handleDelete(record) {
      let _this = this;
      console.log(record);
      modal.confirm({
        title: "确定要删除该部门吗?",
        content: "该操作无法撤回，你还要继续吗？",
        onOk() {
          _this.staffDelete(record);
          // _this.init();
        },
        onCancel() {}
      });
    },
    staffDelete(record) {
      axios({
        method: "",
        url: ""
      })
        .then(res => {
          if (res.data.success) {
            this.$message.success("员工已删除！");
          } else {
            this.$message.error(`${res.data.returnMsg}`);
          }
          // console.log(res.data);
        })
        .catch(error => {
          console.log(error);
        });
    },
    showMessage(val) {
      return val ? val : undefined;
    }
  }
};
</script>

<style scoped>
.steps-content {
  margin-top: 16px;
  border: 1px dashed #e9e9e9;
  border-radius: 6px;
  /*background-color: #fafafa;*/
  min-height: 200px;
  text-align: center;
  padding-top: 80px;
}

.steps-action {
  float: right;
  /*margin-top: 24px;*/
}
.top24 {
  margin-left: -16px;
  margin-bottom: -18px;
}
</style>
